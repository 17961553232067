import { DIMENSIONS, KEYCODE } from '@canalplus/mycanal-commons';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import I18n from '../../lang';
import { isDarkModeSelector } from '../../selectors/application/application-selectors';
import LogoChannel from '../LogoChannel/LogoChannel';
import styles from './Dropdown.css';

const DROPDOWN_ID = 'dropdown-id';
const ARIA_LABEL_MENU = 'menu';

export type Option = {
  label: string;

  /** address params */
  city?: string;
  postcode?: string;
  street?: string;

  /** channel params */
  URLLogoChannelForDarkMode?: string;
  URLLogoChannelForLightMode?: string;
  zapNumber?: number;
};

export type DropdownProps = {
  options?: Option[];
  onClick: (option: Option) => void;
  isInputError?: boolean;
  placeholder?: string;
  errorClassname?: string;
  inputClassname?: string;
  onChange?: (label: string, value: string) => void;
  onFocus?: (label: string, value: string) => void;
  inputValue: string;
  e2e?: string;
  type: 'searchChannels' | 'address';
  selectOnClickOutside?: boolean;
};

export function Dropdown({
  options = [],
  onClick,
  isInputError = false,
  placeholder = '',
  errorClassname = '',
  inputClassname = '',
  onChange = () => null,
  onFocus = () => null,
  inputValue,
  e2e = '',
  type,
  selectOnClickOutside = true,
}: DropdownProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const isDark = useSelector(isDarkModeSelector);

  const divRef = useRef<HTMLDivElement>(null);

  /**
   * Close by calling onClick on the first option
   * When user click outside or press escape
   */
  const close = useCallback(() => {
    if (options.length !== 0 && selectOnClickOutside) {
      // If we're leaving the dropdown, we're selecting the first choice
      onClick(options[0]);
    }
  }, [onClick, options, selectOnClickOutside]);

  /** Click */
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const clickedInside = divRef.current?.contains(e.target as Node);

      if (!clickedInside) {
        close();
      }
    },
    [close]
  );

  /** Keyboard */
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.which === KEYCODE.ESCAPE) {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleKeydown, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeydown, true);
    };
  }, [handleClickOutside, handleKeydown]);

  const dimensions = DIMENSIONS.LOGO_CHANNEL.large;
  const renderOption = (optType: DropdownProps['type'], option: Option, index: number) => {
    switch (optType) {
      case 'address':
        return (
          <>
            <span className={styles.dropdown__street}>{option.street}</span>
            <span className={styles.dropdown__city}>
              {option.city} ({option.postcode})
            </span>
          </>
        );
      case 'searchChannels':
        return (
          <>
            <span className={styles.dropdown__logo}>
              <LogoChannel
                image={{ default: isDark ? option.URLLogoChannelForDarkMode : option.URLLogoChannelForLightMode }}
                dimensions={dimensions}
                altImage={option.label}
              />
            </span>
            <div className={styles.dropdown__channelInfo}>
              <span data-testid={`dropdown__label-${index}`}>{option.label}</span>
              <span className={styles.dropdown__zapNumber}>
                {t('TvGuide.search.zapNumber')} {option.zapNumber}
              </span>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div ref={divRef} className={classNames(styles.inputDropdown, styles[`inputDropdown__${type}`])}>
      <input
        type="text"
        placeholder={placeholder}
        id={type}
        name={type}
        className={classNames(inputClassname, { [errorClassname]: isInputError })}
        onChange={(event) => onChange('label', event.target.value)}
        onFocus={(event) => {
          onFocus('label', event.target.value);
        }}
        value={inputValue}
        autoComplete="desactivate"
        // With React ="off" doesnt work
        // See: https://medium.com/paul-jaworski/turning-off-autocomplete-in-chrome-ee3ff8ef0908
        data-e2e={e2e}
        aria-controls={DROPDOWN_ID}
        aria-haspopup="menu"
        aria-label={placeholder}
      />
      <ul
        id={DROPDOWN_ID}
        role="menu"
        aria-label={`${ARIA_LABEL_MENU}: ${placeholder}`}
        aria-hidden={options.length <= 0}
        className={classNames(styles.dropdown, styles[`dropdown__${type}`])}
      >
        {options.map((option, index) => (
          <li key={`${option.label}_${option.zapNumber || index}`} role="presentation">
            <button
              className={classNames(styles.dropdown__line, styles[`dropdown__line__${type}`])}
              type="button"
              onClick={() => onClick(option)}
              data-e2e={`dropdown__line-${index}`}
              role="menuitem"
            >
              {renderOption(type, option, index)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
