import { Option } from '../../../../../components/Dropdown/Dropdown';
import { LandingSearchActionType, LandingSearchReducerAction } from './actions';

export type LandingSearchReducerState = {
  searchInput: string;
  searchResults: Option[];
  selectedOption?: Option;
};

const landingSearchReducer = (
  state: LandingSearchReducerState,
  { type, payload }: LandingSearchReducerAction
): LandingSearchReducerState => {
  const { searchInput, searchResults, selectedOption } = state;

  switch (type) {
    case LandingSearchActionType.UpdateSearchInput:
      if (typeof payload.searchInput !== 'string' && payload.searchInput === searchInput) {
        return state;
      }

      return {
        ...state,
        searchInput: payload.searchInput,
      };

    case LandingSearchActionType.UpdateSearchResults:
      if (payload.searchResults === searchResults) {
        return state;
      }

      return {
        ...state,
        searchResults: payload.searchResults,
      };

    case LandingSearchActionType.UpdateSelectedOption:
      if (payload.selectedOption === selectedOption) {
        return state;
      }

      return {
        ...state,
        selectedOption: payload.selectedOption,
      };

    default:
      return state;
  }
};

export default landingSearchReducer;
