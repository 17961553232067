import classNames from 'classnames';
import { useSelector } from 'react-redux';
// Store
import { isPhoneResolutionSelector } from '../../../../store/slices/ui-selectors';
import { areAnimationsAutoplaySelector } from '../../../../store/slices/user-selectors';
// Hooks
import { useAreAnimationsMuted } from '../../../../components/HeaderMyCanal/UserMenuMyCanalExtended/UserMenuSections/components/SettingsTV/AnimationsAutoplay/hooks/useAreAnimationsMuted';
// Components
import { Binder } from '@canalplus/one-navigation';
import { VideoProvider, useShortVideoIds } from '../../../../components/Video';
import InfoLayer from '../../../../templates/LandingV5/components/PromotionStrate/components/InfoLayer/InfoLayer';
import MediaLayer from '../../../../templates/LandingV5/components/PromotionStrate/components/MediaLayer/MediaLayer';
// Helpers
import { MIDDLEWARE_CENTER, MIDDLEWARE_STRATE_TOP } from '../../../../helpers/oneNavigation/middleware';
// Types
import { ApiV2PageStratePromotion } from '@dce-front/hodor-types/api/v2/page/dtos/strates/promotion/definitions';
import { VideoContent } from '../../../../components/Video/types';
import {
  PromotionStrateContentData,
  PromotionVideoContent,
} from '../../../../templates/LandingV5/components/PromotionStrate/data/types';
import { PromotionStateTypes } from '../../../../templates/LandingV5/components/PromotionStrate/types';
// Styles
import styles from './PromotionStrate.css';

const getVideoData = ({
  video,
  videoOutro,
}: {
  video?: PromotionVideoContent;
  videoOutro?: PromotionVideoContent;
}): { videoContent: VideoContent; hasVideoLoop: boolean } | undefined => {
  const hasVideoLoop = !video?.url && !!videoOutro?.url;
  const videoData = hasVideoLoop ? videoOutro : video;

  if (videoData?.url) {
    return {
      hasVideoLoop,
      videoContent: {
        encryption: 'clear',
        url: videoData.url,
      },
    };
  }

  return;
};

export type PromotionStrateProps = Pick<ApiV2PageStratePromotion, 'displayParameters'> & {
  /**
   * Data of the strate
   */
  content: PromotionStrateContentData;
  /**
   * The type of the promotion strate: cover or banner
   */
  promotionType: PromotionStateTypes;
};
function PromotionStrate({ content, promotionType }: PromotionStrateProps): JSX.Element {
  const areAnimationsAutoplay = useSelector(areAnimationsAutoplaySelector);
  const { areAnimationsMuted } = useAreAnimationsMuted();
  const { isInShortVideoIds } = useShortVideoIds();

  const isCover = promotionType === PromotionStateTypes.cover;
  const {
    altLogoChannel,
    altLogoType,
    altText,
    button,
    catchlines,
    contentID,
    isOutroOnly,
    media,
    URLLogoChannel,
    URLLogoType,
  } = content;
  const isFullyPlayed = isInShortVideoIds(contentID);
  const isPhoneResolution = useSelector(isPhoneResolutionSelector);
  const { compact, regular } = media;
  const video = isPhoneResolution ? compact?.video || regular?.video : regular?.video;
  const videoOutro = isPhoneResolution ? compact?.videoOutro || regular?.videoOutro : regular?.videoOutro;
  const videoData = getVideoData({ video, videoOutro });
  const hasVideoLoop = videoData?.hasVideoLoop || false;

  return (
    <Binder middleware={isCover ? MIDDLEWARE_STRATE_TOP : MIDDLEWARE_CENTER} forceFocusOnMount={isCover}>
      <div
        className={classNames(styles.promotionStrate, {
          [styles['promotionStrate--banner']]: promotionType === PromotionStateTypes.banner,
          [styles['promotionStrate--cover']]: promotionType === PromotionStateTypes.cover,
        })}
      >
        <VideoProvider isFullyPlayed={isFullyPlayed}>
          <MediaLayer
            alt={altText}
            areAnimationsAutoplay={areAnimationsAutoplay}
            areAnimationsMuted={areAnimationsMuted}
            hasVideoLoop={hasVideoLoop}
            promotionType={promotionType}
            uniqId={hasVideoLoop ? undefined : contentID}
            URLImageCompact={compact?.URLImageDefault}
            URLImageRegular={regular.URLImageDefault}
            videoContent={videoData?.videoContent}
          />
          <div>
            <InfoLayer
              altLogoChannel={altLogoChannel}
              altLogoType={altLogoType}
              button={button}
              catchlines={catchlines}
              isFullyPlayed={areAnimationsAutoplay ? isFullyPlayed : true}
              isOutroOnly={hasVideoLoop ? false : isOutroOnly}
              URLLogoChannel={URLLogoChannel}
              URLLogoType={URLLogoType}
            />
          </div>
        </VideoProvider>
      </div>
    </Binder>
  );
}

export default PromotionStrate;
