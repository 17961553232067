import classNames from 'classnames';
import { useSelector } from 'react-redux';
// Player
import { PlayPauseControlButton, SoundControlButton } from '@canalplus/oneplayer-shared-components';
// Dive
import { Button } from '@canalplus/dive';
// Constants
import { DIMENSIONS } from '@canalplus/mycanal-commons';
// Store
import { displayTVModeSelector } from '../../../../../../store/slices/displayMode-selectors';
// Components
import Linker from '../../../../../../components/Linker/Linker';
import { Actions as VideoActions, useVideoState } from '../../../../../../components/Video';
// Types
import ThumborMediaImage from '../../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { PromotionStrateContentData } from '../../../../../../templates/LandingV5/components/PromotionStrate/data/types';
// Styles
import styles from './InfoLayer.css';

type InfoLayerProps = Pick<
  PromotionStrateContentData,
  'altLogoChannel' | 'altLogoType' | 'button' | 'catchlines' | 'isOutroOnly' | 'URLLogoChannel' | 'URLLogoType'
> & {
  /**
   * If true, the related video has already played once. It can change the display of all elements, except for the button.
   * e. g. The video has fully played, then the user changes page and goes back to this one. The elements are displayed.
   */
  isFullyPlayed: boolean;
};

function InfoLayer({
  altLogoChannel,
  altLogoType,
  button,
  catchlines,
  isFullyPlayed,
  isOutroOnly,
  URLLogoChannel,
  URLLogoType,
}: InfoLayerProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const { isEnded } = useVideoState();
  const canDisplayText = isOutroOnly ? isEnded || isFullyPlayed : true;

  return (
    <div className={styles.infoLayer}>
      <div className={classNames(styles.infoLayer__row, styles.infoLayer__row__top)}>
        <div className={styles.infoLayer__main}>
          {canDisplayText && URLLogoType && (
            <h3
              className={classNames(styles.infoLayer__title, styles.infoLayer__fadeIn)}
              {...(!altLogoType && { 'aria-hidden': true })}
            >
              <ThumborMediaImage
                alt={altLogoType}
                className={styles.infoLayer__logo}
                dimensions={isTvDevice ? DIMENSIONS.LOGO_TITLE_V5_TV : DIMENSIONS.LOGO_TITLE_V5}
                url={URLLogoType}
                width="auto"
              />
            </h3>
          )}
          {canDisplayText &&
            catchlines.map((catchline) => (
              <p
                key={`catchline-${catchline.type}`}
                className={classNames(styles[`infoLayer__catchline-${catchline.type}`], styles.infoLayer__fadeIn)}
              >
                {catchline.label}
              </p>
            ))}
        </div>
        {!isTvDevice && (
          <div className={styles.infoLayer__player_buttons}>
            <VideoActions>
              <PlayPauseControlButton variant="video" />
              <SoundControlButton variant="video" />
            </VideoActions>
          </div>
        )}
      </div>
      <div className={styles.infoLayer__row}>
        {button && (
          <div className={styles.infoLayer__button}>
            <Button
              renderWrapper={({ id, className, children, disabled }) => (
                <Linker
                  ariaLabel={button.ariaLabel}
                  className={className}
                  data={{
                    mainOnClick: button.onClick,
                    ...(button.onClick.contentID && { contentID: button.onClick.contentID }),
                    ...(button.onClick.EpgId && { EpgId: button.onClick.EpgId }),
                    context: button.context,
                  }}
                  disabled={disabled}
                  id={id}
                >
                  {children}
                </Linker>
              )}
            >
              {button.label}
            </Button>
          </div>
        )}
        {URLLogoChannel && (
          <ThumborMediaImage
            alt={altLogoChannel}
            className={styles.infoLayer__channelLogo}
            dimensions={DIMENSIONS.LOGO_V5}
            url={URLLogoChannel}
            width="auto"
          />
        )}
      </div>
    </div>
  );
}

export default InfoLayer;
