import { ViewportToImageDimensions } from '@canalplus/dive';
import classNames from 'classnames';
import { useReducer } from 'react';
import ThumborMediaImage from '../../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { PromotionStateTypes } from '../../../../../../templates/LandingV5/components/PromotionStrate/types';
import styles from './Cover.css';

const BANNER_DIMENSIONS: ViewportToImageDimensions = {
  XLarge: { width: 1920, height: 720 }, // 16:6
  large: { width: 1260, height: 472 },
  medium: { width: 960, height: 360 },
  small: { width: 690, height: 920 }, // 3:4
  XSmall: { width: 320, height: 427 },
};
const COVER_DIMENSIONS: ViewportToImageDimensions = {
  XLarge: { width: 1920, height: 960 },
  large: { width: 1260, height: 630 },
  medium: { width: 960, height: 480 },
  small: { width: 690, height: 1150 },
  XSmall: { width: 320, height: 533 },
};
const DIMENSIONS = {
  promotionBanner: BANNER_DIMENSIONS,
  promotionCover: COVER_DIMENSIONS,
} as const satisfies Record<PromotionStateTypes, ViewportToImageDimensions>;

export type CoverProps = {
  /**
   * The alt of the img
   */
  alt?: string;
  /**
   * If true, display an animated placeholder during the img loading
   */
  hasLoadingAnimation?: boolean;
  /**
   * The type of the promotion strate: cover or banner
   */
  promotionType: PromotionStateTypes;
  /**
   * The url of the image for mobile
   */
  URLImageCompact?: string;
  /**
   * The url of the image for others devices than mobile
   */
  URLImageRegular: string;
};

function Cover({
  alt,
  hasLoadingAnimation = true,
  promotionType,
  URLImageCompact,
  URLImageRegular,
}: CoverProps): JSX.Element {
  const [isLoading, setIsLoading] = useReducer(() => false, hasLoadingAnimation);

  return (
    <ThumborMediaImage
      url={URLImageRegular}
      urlMobile={URLImageCompact}
      dimensions={DIMENSIONS[promotionType]}
      onLoad={setIsLoading}
      alt={alt}
      className={classNames(styles.cover, {
        [styles['cover--loading']]: isLoading,
      })}
    />
  );
}

export default Cover;
